import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import crono from 'vue-crono'
import './index.css'
import '@vtmn/css/dist/index.css'
import '@vtmn/icons/dist/vitamix/font/vitamix.css'
import '@fontsource/roboto'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/400-italic.css'

import excel from 'vue-excel-xlsx'
import { createI18n } from 'vue-i18n'
import {messages} from './plugins/i18n.js'
import {PaginationBar} from 'v-page';
import 'remixicon/fonts/remixicon.css'
import sanitize from './plugins/sanitize'
import {Bar} from 'vue-chartjs'

export const i18n = createI18n({
    locale:  'en', 
    fallbackLocale: 'en', 
    globalInjection: true,
    messages, 
  })
  
createApp(App).use(store).use(sanitize).use(router).use(i18n).use(excel).use(Bar).use(crono).use(PaginationBar,{language:'en',border:true}).mount('#app')
