<template>
  <div v-if="!authenticated">
    <AuthComp />
  </div>
  <div v-else>
    <NavbarComp />
    <div class="mt-1">
      <router-view />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from '@vue/runtime-core';
import { mapActions, mapState } from 'vuex';
import Cookies from 'js-cookie';

export default {
  methods: {
    ...mapActions('auth', ['isAuthenticated', 'setVada']),
  },
  computed: {
    ...mapState('auth', ['authenticated']),
  },
  async mounted() {
    const authCookie = Cookies.get('authenticated');
    if (authCookie === 'true') {
      this.isAuthenticated();
      this.setVada(localStorage.getItem('vada'));
    }
   
  },
  components: {
    NavbarComp: defineAsyncComponent(() =>
      import("./components/NavbarComp.vue")
    ),
    AuthComp: defineAsyncComponent(() =>
      import("./modules/auth/views/AuthView.vue")
    )
  }
};
</script>
