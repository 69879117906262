import planningApi from '@/api/PlanningApi'
import Swal from 'sweetalert2'
import{i18n}  from "@/main.js"
let error = []

export const createPlanning = (context, data) => {
    Swal.fire({
        title: i18n.global.t("loading"),
        allowOutsideClick: false,
        confirmButtonColor: '#414DBC',
        cancelButtonColor: '#6e7d88',
    })
    Swal.showLoading()

    error = []
    const destinies = data[7]
    const initialWeek = data[8]
    const finalWeek = data[9]

    if (!initialWeek) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'initial week is empty',
            confirmButtonColor: '#414DBC',
            cancelButtonColor: '#6e7d88',
        })
        return
    }

    if (!finalWeek) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'final week is empty',
            confirmButtonColor: '#414DBC',
            cancelButtonColor: '#6e7d88',
        })
        return
    }

    const weeks = convertWeeks(initialWeek, finalWeek)

    if (weeks === "Initial week is bigger than final week") {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: weeks,
            confirmButtonColor: '#414DBC',
            cancelButtonColor: '#6e7d88',
        })
        return
    }

    if (weeks === "Initial year is bigger than final year") {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: weeks,
            confirmButtonColor: '#414DBC',
            cancelButtonColor: '#6e7d88',
        })
        return
    }

    const plannings = [];
    weeks.forEach(week => {
        for (let i = 0; i < 7; i++) {
            let j = i + 1
            if (data[i].loadHour) {

                const checks = checkForm(data[i], destinies, j)
                if (checks !== true) {
                    error.push(checks)
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: checks,
                        confirmButtonColor: '#414DBC',
                        cancelButtonColor: '#6e7d88',
                    })
                    return
                }

                const ordertypes = [...data[i].$refs.orderType.map(d => d.value)]
                const downloadhour = [...data[i].$refs.downloadHour.map(d => d.value)]

                const destinationsMonday = destinies.map((d, index) => {
                    if (!d.reference) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: "destiny is empty day:" + j,
                            confirmButtonColor: '#414DBC',
                            cancelButtonColor: '#6e7d88',
                        })
                        error.push('destiny is empty day:' + j)
                        return
                    }
                    if (!ordertypes[index]) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: "order type is empty day:" + j,
                            confirmButtonColor: '#414DBC',
                            cancelButtonColor: '#6e7d88',
                        })

                        error.push('order type is empty day:' + j)
                        return
                    }
                    if (!downloadhour[index]) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: "download hour is empty day:" + j,
                            confirmButtonColor: '#414DBC',
                            cancelButtonColor: '#6e7d88',
                        })
                        error.push('download hour is empty day:' + j)
                        return
                    }
                    return {
                        destiny: {
                            reference: d.reference
                        },
                        ordertype: {
                            reference: ordertypes[index]
                        },
                        hour_download: downloadhour[index] + ":00"
                    }
                })

                plannings.push({
                    planning_center: {
                        carrier: { reference: data[i].carrier },
                        day: j,
                        hour_load: data[i].loadHour + ":00",
                        week,
                        planning_destiny: {
                            comment: data[i].comment,
                            day: j,
                            trucktype: {
                                reference: data[i].trucktype,
                            },
                            week,
                            destinations: destinationsMonday
                        }
                    }
                })
            }
        }
    });

    if (error.length > 0) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: error[0],
            confirmButtonColor: '#414DBC',
            cancelButtonColor: '#6e7d88',
        })

        return
    }

    if (plannings.length === 0) {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: "Form is empty",
            confirmButtonColor: '#414DBC',
            cancelButtonColor: '#6e7d88',
        })
        return
    }
    return savePlanning(plannings)

}

const savePlanning = (plannings) => {
    const body = {
            plannings
    }
    const path = "/api/v1/planningValidate"
    const response = planningApi.post(path, body).then(getResponse => {
    return getResponse.data
    })
    .catch(err => {
        Swal.fire({
            icon: 'error',
            title: 'Error while saving plannings...',
            text: err,
            confirmButtonColor: '#414DBC',
            cancelButtonColor: '#6e7d88',
        })
    return false
    })
    return response
    }

const checkForm = (data, destinies, j) => {
    if (destinies.length === 0) {
        return "destinies empty day: " + j
    }
    if (data.$refs.downloadHour.length !== data.$refs.orderType.length) {
        return "downloadHour is diferent order type day: " + j
    }
    if (destinies.length !== data.$refs.downloadHour.length) {
        return "destinies is diferent download hour day: " + j
    }
    if (data.carrier === "") {
        return "carrier is empty day: " + j
    }
    if (data.loadHour === "") {
        return "load hour is empty day: " + j
    }
    if (data.trucktype === "") {
        return "truck type is empty day: " + j
    }

    return true
}

const convertWeeks = (initialWeek, finalWeek) => {

    const weeks = []
    const initial = yearWeek(initialWeek)
    const final = yearWeek(finalWeek)

    if (initial.year > final.year) {
        return 'Initial year is bigger than final year'
    }


    if (initial.year === final.year) {
        if (initial.week > final.week) {
            return 'Initial week is bigger than final week'
        }
        for (let i = initial.week; i <= final.week; i++) {
            weeks.push(initial.year + "-W" +
                i)
        }
    } else {

        const week1 = initial.week
        const week2 = final.week

        const year1 = initial.year
        const year2 = final.year

        for (let i = year1; i <= year2; i++) {
            for (let j = 1; j < 53; j++) {
                if (year1 == i && j >= week1) {
                    weeks.push(i + "-W" + j)
                }
                if (year2 > i && i > year1) {
                    weeks.push(i + "-W" + j)
                }

            }
        }

        for (let i = 1; i <= week2; i++) {
            weeks.push(year2 + "-W" + i)
        }

    }

    return weeks

}

const yearWeek = (week) => {

    const yearWeek = week.split("-W")
    return {
        year: yearWeek[0],
        week: yearWeek[1]
    }
}

export const deletePlanning = async (context, reference) => {
 const path = `/api/v1/planning/${reference}`
     const response = planningApi.delete(path).then(getResponse => {
        context.commit('deletePlanning', reference)
        return getResponse
      })
      .catch(err => {
          Swal.fire({
              icon: "error",
              title: `Error while deleting the planning`,
              text: err,
              confirmButtonColor: "#007DBC",
              cancelButtonColor: "#6e7d88"
          })
      });
      return response
   
}


export const updateDestiniesAdded = (context, destinies) => {
    context.commit('updateDestiniesAdded', destinies)
}

export const updatePlanning = async (context, form) => {
 const path = `/api/v1/planning`
    const { data } = await planningApi.post(path,  form )
    try{
        return data
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: `Error while updating a planning`,
            text: error,
            confirmButtonColor: "#007DBC",
            cancelButtonColor: "#6e7d88"
        })
    }
}

export const copyPlanning = async (context, body) => {
 Swal.fire({
        title: i18n.global.t("loading"),
        allowOutsideClick: false,
        confirmButtonColor: '#414DBC',
        cancelButtonColor: '#6e7d88',
    })
    Swal.showLoading()
    const path = `/api/v1/copyPlanning`
    try{
    const { data } = await planningApi.post(path,  body )
        Swal.fire({
            icon: 'success',
            text: data.message
        })
    } catch (error) {
        Swal.fire({
            icon: "error",
            title: `Error while copying the planning`,
            text: error,
            confirmButtonColor: "#007DBC",
            cancelButtonColor: "#6e7d88"
        })
    }
}

export const changeSpecificPlanningData = async (context, body) => {
    const path = `/api/v1/changeSpecificPlanningData`
     const response = planningApi.post(path,  body ).then(getResponse => {
        return getResponse.data
        })
        .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while updating the planning`,
            text: err,
            confirmButtonColor: "#007DBC",
            cancelButtonColor: "#6e7d88"
        })
    })
    return response
}

export const deleteSelectedPlannings = async (context,planningsReferences) => {
    Swal.fire({
        title: i18n.global.t("loading"),
        allowOutsideClick: false,
        confirmButtonColor: '#414DBC',
        cancelButtonColor: '#6e7d88',
    })
    Swal.showLoading()
    const path = `/api/v1/deletePlannings`
        const response = planningApi.post(path,planningsReferences).then(getResponse => {
           return getResponse.data
         })
         .catch(err => {
             Swal.fire({
                 icon: "error",
                 title: `Error while deleting the planning`,
                 text: err,
                 confirmButtonColor: "#007DBC",
                 cancelButtonColor: "#6e7d88"
             })
         });
   
         return response
      
   }
