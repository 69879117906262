export default {
    name: 'dataDcmr',
    path: '/data',
    component: () =>
        import ( /* webpackChunkName: "data" */ '@/modules/data/layouts/DataLayout.vue'),
    children: [{
            path: '',
            name: 'search',
            component: () =>
                import ( /* webpackChunkName: "data-search" */ '../views/SearchCmrView.vue'),
        },
        {
            path: ':cmr',
            name: 'cmr',
            component: () =>
                import ( /* webpackChunkName: "data-search-cmr" */ '../views/CmrView.vue'),
        },
        {
            path: ':shipmentNumber',
            name: 'shipmentNumber',
            component: () =>
                import ( /* webpackChunkName: "data-search-cmr" */ '../views/CmrView.vue'),
        },
        {
            path: 'destiny/:vada/:destiny',
            name: 'cmrByDestiny',
            component: () =>
                import ( /* webpackChunkName: "data-search-cmr" */ '../views/CmrsDestiniesView.vue'),
        },
        {
            path: 'carrier/:vada/:carrier',
            name: 'cmrByCarrier',
            component: () =>
                import ( /* webpackChunkName: "data-search-cmr" */ '../views/CmrsCarriersView.vue'),
        },

        {
            path: 'shipments',
            name: 'shipments',
            component: () =>
                import ( /* webpackChunkName: "data-produtivity-cmr" */ '../views/ShipmentsDataView.vue'),
        },
       
        {
            path: 'returns',
            name: 'returns',
            component: () =>
                import ( /* webpackChunkName: "data-manual-cmr" */ '../views/ReturnsDataView.vue'),
        },
       

        {
            path: 'receptionsData',
            name: 'receptionsData',
            component: () =>
                import ( /* webpackChunkName: "data-produtivity-cmr" */ '../views/ReceptionsDataView.vue'),
        }



    ]
}