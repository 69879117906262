import planningApi from "@/api/PlanningApi"
import Swal from "sweetalert2";


export const getCenters = (state) => async () => {
    const path = "/api/v1/centers?delete=false"
    planningApi.get(path)
    .then(response => {
        if (response) {
            state.centers = [...response.data]
            state.isLoading = false
        } else {
            state.isLoading = false
        }
      })

    .catch(err => {
  console.error("Error while getting a center: " + err)
});
}

export const getOrderTypes = (state,getters,rootState) => async () => {
    const vada = rootState.vada
    const path = `/api/v1/ordertypes?delete=false&vada=${vada}`
    planningApi.get(path)
    .then(response => {
    if (response.data) {
        state.ordertypes = [...response.data]
        state.isLoading = false
    } else {
        state.ordertypes = []
        state.isLoading = false
    }
})
    .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while getting orderTypes`,
            text: err.response.data.detail,
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    });
}
    

export const getTruckTypes = (state,getters,rootState) => async () => {
    const vada = rootState.vada
    const path = `/api/v1/trucktypes?delete=false&vada=${vada}`
    planningApi.get(path)
    .then(response => {
    if (response.data) {
        state.trucktypes = [...response.data]
        state.isLoading = false
    } else {
        state.trucktypes = []
        state.isLoading = false
    }
    })
    .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while getting a truck type`,
            text: err.response.data.detail,
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        });
    })
}

export const getDestinies = (state,getters,rootState) => async () => {
    const vada = rootState.vada
    const path = `/api/v1/destinies?delete=false&vada=${vada}`
    planningApi.get(path)
    .then(response => {
   
    if (response.data) {
        state.destinies = [...response.data]
        state.isLoading = false
    } else {
        state.destinies = []
        state.isLoading = false
    }
    })

    .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while creting a destiny`,
            text: err.response.data.detail,
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    })

}

export const getCarriers = (state,getters,rootState) => async () => {
    
    const vada = rootState.vada
    const path = `/api/v1/carriers?delete=false&vada=${vada}`
    planningApi.get(path)
    .then(response => {
    if (response.data) {
        state.carriers = [...response.data]
        state.isLoading = false
    } else {
        state.carriers = []
        state.isLoading = false
    }
    })

    .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while getting a carrier`,
            text: err.response.data.detail,
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    
     })
     state.vada = rootState.vada
}

export const getInvoices = (state,getters,rootState) => async () => {
    const vada = rootState.vada
    const path = `/api/v1/invoices?vada=${vada}&delete=false`
    planningApi.get(path)
    .then(response => {
    if (response) {
        state.invoices = [...response.data]
        state.isLoading = false
    } else {
        state.invoices = []
        state.isLoading = false
    }
    })
    .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while getting an invoice`,
            text: err.response.data.detail,
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })

    })  
}
    
export const getReceptions = (state,getters,rootState) => async () => {
    const vada = rootState.vada
    const path = `/api/v1/receptions?delete=false&vada=${vada}`
    planningApi.get(path)
    .then(response => {
    if (response.data) {
        state.receptions = [...response.data]
        state.isLoading = false
    } else {
        state.receptions = []
        state.isLoading = false
    }
    })

    .catch(err => {
        Swal.fire({
            icon: "error",
            title: `Error while getting receptions`,
            text: err.response.data.detail,
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    })

}

