import cmrApi from "@/api/CmrApi"
import Swal from 'sweetalert2'
import { i18n } from '@/main.js'

export const cmrReceptionStore = async (context, { form }) => {
  const cmr_number = form.cmr_number;
  const body = {
    store_reception_date_time: form.store_reception_date_time
  };
  Swal.fire({
    title: i18n.global.t('waitPlease'),
    allowOutsideClick: false,
    confirmButtonColor: "#414DBC",
    cancelButtonColor: "#6e7d88"
  });
  Swal.showLoading();

  try {
    const response = await cmrApi.put(`/api/v1/cmrReceptionStore/${cmr_number}`, body, {
      headers: {
        'Content-Type': 'application/json' 
      }
    });
    Swal.fire({
      title: i18n.global.t('successInStore'),
      icon: "success"
    });
    return response;
  } catch (err) {
 
    Swal.fire({
      icon: "error",
      title: `Error while registering CMR reception datetime`,
      text: err.response?.data?.detail || 'An unexpected error occurred',
      confirmButtonColor: "#414DBC",
      cancelButtonColor: "#6e7d88"
    });
    throw err; 
  }
 
}
