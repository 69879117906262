export default {
    name: 'decaconvo',
    path: '/decaconvo',
    component: () =>
        import(/* webpackChunkName: "Decaconvo" */ '@/modules/decaconvo/layouts/DecaconvoLayout.vue'),
    children: [{
        name: 'controlDecaconvo',
        path: '',
        component: () =>
            import(/* webpackChunkName: "Control" */ '@/modules/decaconvo/views/ControlDecaconvo.vue')

    }]


}