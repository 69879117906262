import axios from 'axios'
const uri = process.env.VUE_APP_HOST_PLANNING

const planningApi = axios.create({
    headers:{
        'x-api-key':process.env.VUE_APP_API_KEY_PLANNING
    },
    baseURL: uri
})

planningApi.interceptors.request.use(
    (config) => {
  
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
        Promise.reject("AUTH ERROR OCCURRED:" + error);
    }
  );

export default planningApi