import Cookies from 'js-cookie';
import { encrypt } from "@/modules/auth/auth";

export const openFedid = (context, data) => {
    const url = `https:${data.environment === 'production' ? '' : 'preprod.'}idpdecathlon.oxylane.com/as/authorization.oauth2?response_type=code&client_id=${data.clientId}&state=myRandomState&scope=openid%20profile&redirect_uri=${window.location.origin}/`;
    window.location.href = url;
}

export const createUserInStorage = async (context, profile) => {
    const user = profile.uid;
    const givenName = profile.givenName;
    const vada = profile.site;
    const locale = profile.preferredLanguage;
    const roles = profile.role;
    let rolesFormated = JSON.stringify(roles);
    let dcmrRoles = assignRole(rolesFormated);   
    encrypt('roles', dcmrRoles);
    encrypt('username', user);
    encrypt(vada);
    localStorage.setItem('givenName', givenName);
    localStorage.setItem('vada', vada);
    localStorage.setItem('locale', locale);
    const today = new Date();
    localStorage.setItem("dateLoginToday",today.getTime())
}

export const saveDataInStorage = (context, data) => {
    setDataProfile(data.profile);
    setDataToken(data.token);
    Cookies.set('authenticated', true, { expires: 0.5 });
}

export const isAuthenticated = ({ commit }) => {
    commit('isAuthenticated');
}

export const isAuthenticatedToFalse = ({ commit }) => {
    commit('isAuthenticatedToFalse');
    Cookies.remove('authenticated');
}

const setDataToken = (dataToken) => {
    const attributesToken = {
        access_token: dataToken.access_token,
        expires_in: dataToken.expires_in,
        id_token: dataToken.id_token,
        refresh_token: dataToken.refresh_token,
        token_type: dataToken.token_type,
        exp: getDate()
    };
    localStorage.setItem('attributesToken', JSON.stringify(attributesToken))
    localStorage.setItem('token', dataToken.access_token)
}

export const setNewDataToken = (context, data) => {
    let tokenData = JSON.parse(localStorage.getItem('attributesToken'));
    tokenData.access_token = data.token.access_token;
    tokenData.expires_in = data.token.expires_in;
    tokenData.refresh_token = data.token.refresh_token;
    tokenData.token_type = data.token.token_type;
    localStorage.setItem('attributesToken', JSON.stringify(tokenData))
    localStorage.setItem('token',tokenData.access_token)
}

const getDate = () => {
    const today = new Date();
    today.setHours(today.getHours() + 12);
    return today;
}

const setDataProfile = (dataProfile) => {
    const attributesDecathlon = {
        allsites: dataProfile.allsites,
        c: dataProfile.c,
        cn: dataProfile.cn,
        costcenter: dataProfile.costcenter,
        displayName: dataProfile.displayName,
        entryUUID: dataProfile.entryUUID,
        familyName: dataProfile.familyName,
        federation_idp: dataProfile.federation_idp,
        givenName: dataProfile.givenName,
        hrid: dataProfile.hrid,
        jobname: dataProfile.jobname,
        mail: dataProfile.mail,
        objectclass: dataProfile.objectclass,
        partyNumber: dataProfile.partyNumber,
        preferredLanguage: dataProfile.preferredLanguage,
        role: dataProfile.role,
        site: dataProfile.site,
        sitename: dataProfile.sitename,
        sitepartynumber: dataProfile.sitepartynumber,
        sitetype: dataProfile.sitetype,
        sn: dataProfile.sn,
        sub: dataProfile.sub,
        title: dataProfile.title,
        uid: dataProfile.uid,
        uuid: dataProfile.uuid
    };
    localStorage.setItem('attributesDecathlon', JSON.stringify(attributesDecathlon))
}

export const setVada = (context, vada) => {
    context.commit('setVada', vada);
}

const assignRole = (rolesFormated) => {
    let dcmrRoles = null;
    let itSuperAdmin = "DCMR_ITSUPERADMIN";
    let superAdmin = "DCMR_SUPERADMIN";
    let admin = "DCMR_RESPONSIBLE_QUAI_TRANSPORT";
    let superUser = "DCMR_GENERIC_USER_WAREHOUSE_RQT";
    let userVada = "DCMR_GENERIC_USER_VADA";
    let userStore = "DCMR_GENERIC_USER_STORE";
    let user = "DCMR_GENERIC_USER_WAREHOUSE";

    if (rolesFormated.includes(itSuperAdmin)) {
        localStorage.setItem("superit", "ROLE_IT_SUPER_ADMIN")
    }

    if (rolesFormated.includes(superAdmin) || rolesFormated.includes(itSuperAdmin)) {
        dcmrRoles = "ROLE_SUPER_ADMIN";
    }
    if (rolesFormated.includes(admin) && !rolesFormated.includes(superAdmin) && !rolesFormated.includes(itSuperAdmin)) {
        dcmrRoles = "ROLE_ADMIN";
    }        
    if (rolesFormated.includes(superUser) && !rolesFormated.includes(superAdmin) && !rolesFormated.includes(itSuperAdmin)) {
        dcmrRoles = "ROLE_ADMIN";
    }
    if (!rolesFormated.includes(superAdmin) && !rolesFormated.includes(itSuperAdmin) && !rolesFormated.includes(admin) && !rolesFormated.includes(superUser) && !rolesFormated.includes(user) && rolesFormated.includes(userVada)) {
        dcmrRoles = "ROLE_USER_VADA";
    }
    if (!rolesFormated.includes(superAdmin) && !rolesFormated.includes(itSuperAdmin) && !rolesFormated.includes(admin) && !rolesFormated.includes(superUser) && !rolesFormated.includes(userVada) && !rolesFormated.includes(user) && rolesFormated.includes(userStore)) {
        dcmrRoles = "ROLE_USER_STORE";
    }
    if (!rolesFormated.includes(superAdmin) && !rolesFormated.includes(itSuperAdmin) && !rolesFormated.includes(admin) && rolesFormated.includes(user) && !rolesFormated.includes(superUser)) {
        dcmrRoles = "ROLE_USER";
    }
    if (!rolesFormated.includes(superAdmin) && !rolesFormated.includes(itSuperAdmin) && !rolesFormated.includes(user) && !rolesFormated.includes(admin) && !rolesFormated.includes(superUser) && !rolesFormated.includes(userVada) && !rolesFormated.includes(userStore)) {
        dcmrRoles = "NO_ROLE";
    }
    return dcmrRoles;
}
