import planningApi from "@/api/PlanningApi"
import { checkEmail } from "../../helpers/ValidateEmail";

/**
 * create a new center
 * 
 * @param {*} context auto vuex
 * @param {*} form body to save 
 * @returns 
 */
export const saveCenter = async(context, form) => {
    const body = {...form } 
    const path = "/api/v1/center"
    return planningApi.post(path, body);
}

/**
 * create a new carrier
 * 
 * @param {*} context auto vuex
 * @param {*} form body to save 
 * @returns 
 */
export const saveCarrier = async(context, form) => {
    const body = {...form }
    const path = "/api/v1/carrier"
    return planningApi.post(path, body);
}

/**
 * create a new order type
 * 
 * @param {*} context vuex
 * @param {*} form  body to save
 * @returns 
 */
export const saveOrderType = async(context, form) => {
    const body = {...form } 
    const path = "/api/v1/ordertype"
    return planningApi.post(path, body)

}

/**
 * create a new truck type
 * 
 * @param {*} context vuex
 * @param {*} form  body to save
 * @returns 
 */
export const saveTruckType = async(context, form) => {
    const body = {...form }
    const path = "/api/v1/trucktype"
    return planningApi.post(path, body)

}

/**
 * create a new destiny
 * 
 * @param {*} context vuex
 * @param {*} form  body to save
 * @returns 
 */
export const saveDestiny = async(context, form) => {
    const body = {...form } 
    const path = "/api/v1/destiny"
    return planningApi.post(path, body)

}

/**
 * create a new invoice
 * 
 * @param {*} context vuex
 * @param {*} form  body to save
 * @returns 
 */
export const saveInvoice = async(context, form) => {
    const body = {...form }
    const path = "/api/v1/invoice"
    return planningApi.post(path, body)

}

/**
 * create a new reception
 * 
 * @param {*} context vuex
 * @param {*} form  body to save
 * @returns 
 */

export const saveReception = async(context, form) => {
    const body = {...form } 
    const path = "/api/v1/reception"
    return planningApi.post(path, body)

}

/**
 * delete center
 * 
 * @param {*} param0 vuex
 * @param {*} reference reference center 
 * @returns 
 */
export const deleteCenter = async({ commit }, reference) => {
    const path = `/api/v1/center/${reference}`
    const data = await planningApi.delete(path)

    if (data.status === 200) {
        commit('deleteCenter', reference)
        return data.status
    }
    return data.status
}

/**
 * delete carrier
 * 
 * @param {*} param0 vuex
 * @param {*} reference reference center 
 * @returns 
 */
export const deleteCarrier = async({ commit }, reference) => {
    const path = `/api/v1/carrier/${reference}`
    const data = await planningApi.delete(path)

    if (data.status === 200) {
        commit('deleteCarrier', reference)
        return data.status
    }
    return data.status
}

/**
 * delete order type
 * 
 * @param {*} param0 vuex 
 * @param {*} reference reference order type
 * @returns 
 */
export const deleteOrderType = async({ commit }, reference) => {
    const path = `/api/v1/ordertype/${reference}`
    const data = await planningApi.delete(path)

    if (data.status === 200) {
        commit('deleteOrderType', reference)
        return data.status
    }
    return data.status
}

/**
 * delete destiny
 * 
 * @param {*} param0 vuex 
 * @param {*} reference reference truck type
 * @returns 
 */
export const deleteDestiny = async({ commit }, reference) => {
    const path = `/api/v1/destiny/${reference}`
    const data = await planningApi.delete(path)

    if (data.status === 200) {
        commit('deleteDestiny', reference)
        return data.status
    }
    return data.status
}


/**
 * delete truck type
 * 
 * @param {*} param0 vuex 
 * @param {*} reference reference truck type
 * @returns 
 */
export const deleteTruckType = async({ commit }, reference) => {
    const path = `/api/v1/trucktype/${reference}`
    const data = await planningApi.delete(path)

    if (data.status === 200) {
        commit('deleteTruckType', reference)
        return data.status
    }
    return data.status
}

/**
 * delete invoice
 * 
 * @param {*} param0 vuex 
 * @param {*} reference reference invoice 
 * @returns 
 */
export const deleteInvoice = async({ commit }, reference) => {
    const path = `/api/v1/invoice/${reference}`
    const { data } = await planningApi.delete(path)

    if (data.status === 200) {
        commit('deleteInvoice', reference)
        return data.status
    }
    return data.status
}


/**
 * delete destiny
 * 
 * @param {*} param0 vuex 
 * @param {*} reference reference truck type
 * @returns 
 */
export const deleteReception = async({ commit }, reference) => {
    const path = `/api/v1/reception/${reference}`
    const data = await planningApi.delete(path)

    if (data.status === 200) {
        commit('deleteReception', reference)
        return data.status
    }
    return data.status
}



/**
 * Method check if empty fields and email invalid
 * 
 * @param {*} context vuex
 * @param {*} form body to save
 * @returns 
 */
export const checkCenter = (context, form) => {

    if (!form.vada) {
        return 'Vada is empty'
    }
    if (!form.name) {
        return 'Name is empty'
    }
    if (!form.address) {
        return 'Address is empty'
    }
    if (!form.city) {
        return 'City is empty'
    }

    if (!form.cif) {
        return 'Cif is empty'
    }
    if (!form.country) {
        return 'Country is empty'
    }
    if (!form.email_rqt) {
        return 'Email is empty'
    }
    if (!form.type_warehouse)
        return 'Type Warehouse Empty'
        
    if (!checkEmail(form.email_rqt)) {
        return 'Invalid email format'
    }

    return true
}

/**
 * Method check if empty fields and email invalid
 * 
 * @param {*} context vuex
 * @param {*} form body to save
 * @returns 
 */

export const checkCarrier = (context, form) => {
    
    if (!form.name) {
        return 'Name is empty'
    }
    if (!form.address) {
        return 'Address is empty'
    }
    if (!form.cif) {
        return 'Cif is empty'
    }
    if (!form.email) {
        return 'Email is empty'
    }
    if (!checkEmail(form.email)) {
        return 'Invalid email format'
    }
    return true
}

export const checkDestiny = (context, form) => {

    if (!form.name) {
        return "Name empty"
    }
    if (!form.number) {
        return "Number empty"
    }
    if (!form.email_destiny) {
        return "Email destiny empty"
    }
    if (!checkEmail(form.email_destiny)) {
        return "Invalid email destiny format"
    }

    if (!form.dock) {
        return "Dock empty"
    }
    if (!form.address) {
        return "Address empty"
    }
    if (!form.load_order) {
        return "Load order empty"
    }

    if (!form.kilometers) {
        return "kilometrs empty"
    }

    return true
}

/**
 * method for validate field name
 * 
 * @param {*} context vuex
 * @param {*} form body 
 * @returns 
 */
export const checkOrderType = (context, form) => {
    if (!form.name) {
        return 'Name is empty'
    }

    return true
}

/**
 * method for validate field name
 * 
 * @param {*} context vuex
 * @param {*} form body 
 * @returns 
 */
export const checkTruckType = (context, form) => {
    if (!form.name) {
        return 'Name is empty'
    }
    if (form.load_time === 0 || !form.load_time) {
        return 'load time is empty'
    }

    return true
}

/**
 * method for validate field name
 * 
 * @param {*} context vuex
 * @param {*} form body 
 * @returns 
 */
export const checkInvoice = (context, form) => {
    if (!form.destiny.reference) {
        return 'Destiny is empty'
    }
    if (!form.type_truck.reference) {
        return 'Truck Type is empty'
    }
    if (!form.price) {
        return 'Price is empty'
    }

    return true
}

/**
 * method for validate field name
 * 
 * @param {*} context vuex
 * @param {*} form body 
 * @returns 
 */
export const checkReception = (context, form) => {
  
    if (!form.name) {
        return 'Reception name is empty'
    }


    const [fromTime, toTime] = form.interval.split('-');
  
    if (!fromTime || fromTime=="undefined") {
      return 'From is empty'
    }
    if (!toTime || toTime=="undefined") {
      return 'To is empty'
    }

    if (!form.interval) {
        return 'Interval is empty'
    }
    
          
    return true
}


/**
 * editMode used to change the state of the form from create to update
 * 
 * @param {*} context 
 * @param {*} value 
 */
export const onEditMode = (context, value) => {
    context.commit('editMode', value)
}

/**
 * it is to save the vada in context state, so the user only declares vada once
 * 
 * @param {*} context 
 * @param {*} value 
 */
export const changeVada = (context, value) => {
    context.commit('changeVada', value)
}