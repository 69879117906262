export default {
    name: 'CollaboratorDcmr',
    path: '/collaborator',
    component: () =>
        import ( /* webpackChunkName: "collaboratorDcmr" */ '@/modules/collaborator/layouts/CollaboratorLayout.vue'),
    children: [{
            path: '',
            name: 'collaborator',
            component: () =>
                import ( /* webpackChunkName: "collaborator" */ '../views/CollaboratorView.vue'),
        },
        {
            path: '/expedition/:cmr',
            name: 'collaboratorByCmr',
            component: () =>
                import ( /* webpackChunkName: "close cmr" */ '../views/CloseCmrView.vue'),
        },
        {
            path: '/return/:cmr',
            name: 'returnByCmr',
            component: () =>
                import ( /* webpackChunkName: "close cmr" */ '../views/CloseReturnView.vue'),
        }
    ]
}