export default () => ({
    isLoading: true,
    cmr: null,
    returns: [],
    fuels: [],
    processes: [],
    cmrs: [],
    receptions:[],
    vada:'',
    cmrCount:'',
    cmrCountDestiny:[],
    cmrCountCarrier:[],
    shipmentsData: [],
    shipmentsDataNotPaginated: [],
    returnsData : []

})