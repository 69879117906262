export default {
    name: 'planningDcmr',
    path: '/planning',
    component: () =>
        import ( /* webpackChunkName: "dcmr-planning" */ '@/modules/planning/layouts/PlanningDcmr.vue'),
    children: [{
            path: '',
            name: 'planning',
            component: () =>
                import ( /* webpackChunkName: "planning-transport" */ '../views/PlanningView.vue'),
        },
        {
            path: 'transport',
            name: 'transport',
            component: () =>
                import ( /* webpackChunkName: "planning-transport" */ '../views/TransportView.vue'),
        }


    ]
}