import cmrApi from "@/api/CmrApi"
import Swal from "sweetalert2"


export const getCmrByNumber = (state) => (number) => {
    const path = `/api/v1/cmrByNumber?cmrNumber=${number}`
    const response = cmrApi.get(path).then(getResponse => {
    state.cmr = getResponse.data
    state.isLoading = false
    return getResponse.data
})
.catch(err => {
    state.cmr = []
    Swal.fire({
        icon: "error",
        title: `No se ha encontrado ningún CMR con este número`,
        text: err,
        confirmButtonColor: "#007DBC",
        cancelButtonColor: "#6e7d88"
    })
  
})
return response
}

export const getCmrByShipmentNumber = (state) => (shipmentNumber) => {
    const path = `/api/v1/cmrByShipmentNumber?shipmentNumber=${shipmentNumber}`
    const response = cmrApi.get(path).then(getResponse => {
        state.cmr = getResponse.data
        state.isLoading = false
        return getResponse.data
    })
    .catch(err => {
        state.cmr = []
        Swal.fire({
            icon: "error",
            title: `No se ha encontrado ningún CMR para este número de expedición.`,
            text: `Asegúrate de no introducir el prefijo de la expe (82) si no es necesario. Recuerda que el número de expe tiene que ser idéntico al número de expe que se usó para cerrar el CMR: ` + err ,
            confirmButtonColor: "#007DBC",
            cancelButtonColor: "#6e7d88"
        })
    
    })
    return response
   
}

export const cmrsByDestiny = (state,getters,rootState) => async (destiny,{page,size,order}) => {
   
    const vada = rootState.vada;
    Swal.fire({
        title: "Espere por favor",
        allowOutsideClick: false,
        confirmButtonColor: "#007DBC",
        cancelButtonColor: "#6e7d88"
      });
    Swal.showLoading(); 

        const path = `/api/v1/cmrsByDestiny?vada=${vada}&destiny=${destiny}&page=${page}&size=${size}&sort=${order}`
        const response = await cmrApi.get(path)
        if (response.status != 200) {
            Swal.fire({
                icon: "error",
                title: `Error ${response.status}`,
                text: response.statusText,
                confirmButtonColor: "#007DBC",
                cancelButtonColor: "#6e7d88"
            })
        }
      
        
        state.cmrs = [...response.data.content]
        Swal.close()
        return response.data
    
}

export const cmrsByCarrier = (state,getters,rootState) => async (carrier,{page,size,order}) => {
 
    const vada = rootState.vada;
    Swal.fire({
        title: "Espere por favor",
        allowOutsideClick: false,
        confirmButtonColor: "#007DBC",
        cancelButtonColor: "#6e7d88"
      });
    Swal.showLoading(); 

        const path = `/api/v1/cmrsByCarrier?vada=${vada}&carrier=${carrier}&page=${page}&size=${size}&sort=${order}`
        const response = await cmrApi.get(path)
        if (response.status != 200) {
            Swal.fire({
                icon: "error",
                title: `Error ${response.status}`,
                text: response.statusText,
                confirmButtonColor: "#007DBC",
                cancelButtonColor: "#6e7d88"
            })
        }
      
        
        state.cmrs = [...response.data.content]
        Swal.close()
        return response.data
    
}

export const getReturns = (state,getters,rootState) => async () => {
    const vada = rootState.vada
    const path = `/api/v1/return?vada=${vada}`
    const response = await cmrApi.get(path)
    if (response.status != 200) {
        Swal.fire({
            icon: "error",
            title: `Error while getting returns`,
            text: response.statusText,
            confirmButtonColor: "#007DBC",
            cancelButtonColor: "#6e7d88"
        })
    }

    if (response.data) {
        state.returns = [...response.data]
        state.isLoading = false
    } else {
        state.returns = []
        state.isLoading = false
    }
}

export const getRegistration = (state) => async (registrationName) => {
    const path = `/api/v1/registration?registrationName=${registrationName}`
    const response = await cmrApi.get(path)
    if (response.status != 200) {
        Swal.fire({
            icon: "error",
            title: `Error ${response.status}`,
            text: response.statusText,
            confirmButtonColor: "#007DBC",
            cancelButtonColor: "#6e7d88"
        })
    }

    if (response.data[0] === 'false') {
        state.fuels = response.data[2]
        state.showFuel = true
    }

    return response.data[0]
}

export const getDriver = () => async (driverNif) => {
    const path = `/api/v1/driver?driverNif=${driverNif}`
    const response = await cmrApi.get(path)
    if (response.status != 200) {
        Swal.fire({
            icon: "error",
            title: `Error while getting driver`,
            text: response.statusText,
            confirmButtonColor: "#007DBC",
            cancelButtonColor: "#6e7d88"
        })
    }
    if (!response.data) return
    return response.data[0]
}

export const getProcesses = (state,getters,rootState) => async () => {
    const path = `/api/v1/processes`
    const response = await cmrApi.get(path)
    if (response.status != 200) {
        Swal.fire({
            icon: "error",
            title: `Errorwhile getting processes`,
            text: response.statusText,
            confirmButtonColor: "#007DBC",
            cancelButtonColor: "#6e7d88"
        })
    }
    if (!response.data) return
    state.processes = [...response.data]
    state.isLoading = false
    state.vada = rootState.vada
    
}

export const getCmrs = (state,getters,rootState) => async (initialDate, finalDate) => {
    const vada = rootState.vada
    const path = `/api/v1/cmrByDates?initialDate=${initialDate}&finalDate=${finalDate}&vada=${vada}`
    const response = await cmrApi.get(path)
    if (response.status != 200) {
        Swal.fire({
            icon: "error",
            title: `Error while getting cmrs`,
            text: response.statusText,
            confirmButtonColor: "#007DBC",
            cancelButtonColor: "#6e7d88"
        })
    }
    if (!response.data) return
    state.cmrs = [...response.data]
    return [...response.data]
}



// GET SHIPMENT DATA
export const getShipmentsData = (state,getters,rootState) => async (params, {page,size,order}) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
        confirmButtonColor: '#414DBC',
        cancelButtonColor: '#6e7d88',
    })
    Swal.showLoading()
    let vada
    let countryView
    if(params.country !== 'Mi centro'){
    vada = params.country
    countryView = true    
    }else{
    vada = rootState.vada;
    countryView = false
    }
    let path
    if(params.destiny === 'Todos los destinos' && params.carrier === 'Todos los transportistas'){
        path = `/api/v1/shipmentsData?initialDate=${params.initialDate}&finalDate=${params.finalDate}&vada=${vada}&sector=${params.sectorSelected}&excel=${params.excel}&sort=${order}&page=${page}&size=${size}&returns=${params.returns}&countryView=${countryView}`;
    }else if(params.destiny !== 'Todos los destinos' && params.carrier === 'Todos los transportistas' ) {
        path = `/api/v1/shipmentsData?initialDate=${params.initialDate}&finalDate=${params.finalDate}&vada=${vada}&sector=${params.sectorSelected}&excel=${params.excel}&sort=${order}&page=${page}&size=${size}&returns=${params.returns}&destiny=${params.destiny}&countryView=${countryView}`;
    }else if(params.destiny === 'Todos los destinos' && params.carrier !== 'Todos los transportistas' ){
        path = `/api/v1/shipmentsData?initialDate=${params.initialDate}&finalDate=${params.finalDate}&vada=${vada}&sector=${params.sectorSelected}&excel=${params.excel}&sort=${order}&page=${page}&size=${size}&returns=${params.returns}&countryView=${countryView}&carrier=${params.carrier}`;
    }else if(params.destiny !== 'Todos los destinos' && params.carrier !== 'Todos los transportistas' ){
        path = `/api/v1/shipmentsData?initialDate=${params.initialDate}&finalDate=${params.finalDate}&vada=${vada}&sector=${params.sectorSelected}&excel=${params.excel}&sort=${order}&page=${page}&size=${size}&returns=${params.returns}&destiny=${params.destiny}&countryView=${countryView}&carrier=${params.carrier}`;
    }
    
    const response = await cmrApi.get(path);
    state.shipmentsData = response.data.content;
    Swal.close()
    return response.data;
  };

  export const getShipmentsDataNotPageable = (state,getters,rootState) => async (initialDate, finalDate,destiny,country,carrier) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
        confirmButtonColor: '#414DBC',
        cancelButtonColor: '#6e7d88',
    })
    Swal.showLoading()
    let vada
    let countryView
    if(country !== 'Mi centro'){
    vada = country
    countryView = true    
    }else{
    vada = rootState.vada;
    countryView = false
    }
    let path
    if(destiny === 'Todos los destinos' && carrier === 'Todos los transportistas'){
        path = `/api/v1/shipmentsDataNotPageable?initialDate=${initialDate}&finalDate=${finalDate}&vada=${vada}&countryView=${countryView}`;
    }else if(destiny !== 'Todos los destinos' && carrier === 'Todos los transportistas' ) {
        path = `/api/v1/shipmentsDataNotPageable?initialDate=${initialDate}&finalDate=${finalDate}&vada=${vada}&destiny=${destiny}&countryView=${countryView}`;
    }else if(destiny === 'Todos los destinos' && carrier !== 'Todos los transportistas' ) {
        path = `/api/v1/shipmentsDataNotPageable?initialDate=${initialDate}&finalDate=${finalDate}&vada=${vada}&countryView=${countryView}&carrier=${carrier}`;
    }else if(destiny !== 'Todos los destinos' && carrier !== 'Todos los transportistas' ) {
        path = `/api/v1/shipmentsDataNotPageable?initialDate=${initialDate}&finalDate=${finalDate}&vada=${vada}&destiny=${destiny}&countryView=${countryView}&carrier=${carrier}`;
    }
   
    const response = await cmrApi.get(path);
    state.shipmentsDataNotPaginated = response.data;
    Swal.close()
    return response.data;
}

export const getShipmentsExcel = (state,getters,rootState) => async (params) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
        confirmButtonColor: '#414DBC',
        cancelButtonColor: '#6e7d88',
    })
    Swal.showLoading()
    let vada
    let countryView
    if(params.country !== 'Mi centro'){
    vada = params.country
    countryView = true    
    }else{
    vada = rootState.vada;
    countryView = false
    }
    let formatedInitialDate = params.initialDate+" 00:00:00"
    let formatedFinalDate = params.finalDate+" 23:59:59"
    let path
    if(params.destiny === 'Todos los destinos' && params.carrier === 'Todos los transportistas'){
        path = `/api/v1/shipmentsData?initialDate=${formatedInitialDate}&finalDate=${formatedFinalDate}&vada=${vada}&sector=${params.sectorSelected}&excel=${params.excel}&returns=${params.returns}&countryView=${countryView}`

    }else if(params.destiny !== 'Todos los destinos' && params.carrier === 'Todos los transportistas' ) {
        path = `/api/v1/shipmentsData?initialDate=${formatedInitialDate}&finalDate=${formatedFinalDate}&vada=${vada}&sector=${params.sectorSelected}&excel=${params.excel}&returns=${params.returns}&destiny=${params.destiny}&countryView=${countryView}`
    }else if(params.destiny === 'Todos los destinos' && params.carrier !== 'Todos los transportistas' ) {
        path = `/api/v1/shipmentsData?initialDate=${formatedInitialDate}&finalDate=${formatedFinalDate}&vada=${vada}&sector=${params.sectorSelected}&excel=${params.excel}&returns=${params.returns}&countryView=${countryView}&carrier=${params.carrier}`
    }else if(params.destiny !== 'Todos los destinos' && params.carrier !== 'Todos los transportistas' ) {
        path = `/api/v1/shipmentsData?initialDate=${formatedInitialDate}&finalDate=${formatedFinalDate}&vada=${vada}&sector=${params.sectorSelected}&excel=${params.excel}&returns=${params.returns}&destiny=${params.destiny}&countryView=${countryView}&carrier=${params.carrier}`       
    }

    const response = await cmrApi.get(path);
    state.cmrs = response.data.content;
    Swal.close()
    return response.data
}




export const getCmrCountGroupedByDestiny = (state,getters,rootState) => async (fromDate, toDate,returns,destiny,country,carrier) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false
    })
    Swal.showLoading()
    let vada
    let countryView
    if(country !== 'Mi centro'){
    vada = country
    countryView = true    
    }else{
    vada = rootState.vada;
    countryView = false
    }
    let path
    if(destiny === 'Todos los destinos' && carrier === 'Todos los transportistas'){
        path = `/api/v1/cmrCountGroupedByDestiny?vada=${vada}&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}&countryView=${countryView}`;
    }else if(destiny !== 'Todos los destinos' && carrier === 'Todos los transportistas' ) {
        path = `/api/v1/cmrCountGroupedByDestiny?vada=${vada}&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}&destiny=${destiny}&countryView=${countryView}`;
    }else if(destiny === 'Todos los destinos' && carrier !== 'Todos los transportistas' ) {
        path = `/api/v1/cmrCountGroupedByDestiny?vada=${vada}&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}&countryView=${countryView}&carrier=${carrier}`;
    }else if(destiny !== 'Todos los destinos' && carrier !== 'Todos los transportistas' ) {
        path = `/api/v1/cmrCountGroupedByDestiny?vada=${vada}&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}&destiny=${destiny}&countryView=${countryView}&carrier=${carrier}`;
    
    }
    
  
    const response = await cmrApi.get(path);
    state.cmrCountDestiny = response.data;
    Swal.close()
    return response.data
}

export const getCmrCountGroupedByCarrier = (state,getters,rootState) => async (fromDate, toDate,returns,destiny, country,carrier) => {
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
        
    })
    Swal.showLoading()
    let vada
    let countryView
    if(country !== 'Mi centro'){
    vada = country
    countryView = true    
    }else{
    vada = rootState.vada;
    countryView = false
    }
    let path
    if(destiny === 'Todos los destinos' && carrier === 'Todos los transportistas'){
        path = `/api/v1/cmrCountGroupedByCarrier?vada=${vada}&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}&countryView=${countryView}`;

    }else if(destiny !== 'Todos los destinos' && carrier === 'Todos los transportistas' ) {
        path = `/api/v1/cmrCountGroupedByCarrier?vada=${vada}&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}&destiny=${destiny}&countryView=${countryView}`;
    }else if(destiny === 'Todos los destinos' && carrier !== 'Todos los transportistas' ) {
        path = `/api/v1/cmrCountGroupedByCarrier?vada=${vada}&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}&countryView=${countryView}&carrier=${carrier}`;
    }else if(destiny !== 'Todos los destinos' && carrier !== 'Todos los transportistas' ) {
        path = `/api/v1/cmrCountGroupedByCarrier?vada=${vada}&dateFrom=${fromDate+ " 00:00:00"}&dateTo=${toDate+ " 23:59:59"}&returns=${returns}&destiny=${destiny}&countryView=${countryView}&carrier=${carrier}`;
    
    }
    
    const response = await cmrApi.get(path);
    state.cmrCountCarrier = response.data;
    Swal.close()
    return response.data
}

export const getReceptionPageable = (state,getters,rootState) => async (initialDate, finalDate,{page,size,order}) => {
    let formatedInitialDate = initialDate+" 00:00:00"
    let formatedFinalDate = finalDate+" 23:59:59"
    const vada = rootState.vada
    const path = `/api/v1/receptionData?initialDate=${formatedInitialDate}&finalDate=${formatedFinalDate}&vada=${vada}&page=${page}&size=${size}&sort=${order}`
    const response= await cmrApi.get(path)
    state.receptions = response.data.content
    return response.data
}
export const getReceptionXls = (state,getters,rootState) => async (initialDate, finalDate) => {
    let formatedInitialDate = initialDate+" 00:00:00"
    let formatedFinalDate = finalDate+" 23:59:59"
    Swal.fire({
        title: 'Espere por favor',
        allowOutsideClick: false,
        confirmButtonColor: '#414DBC',
        cancelButtonColor: '#6e7d88',
    })
    Swal.showLoading()
    const vada = rootState.vada
    const path = `/api/v1/receptionData/xls?initialDate=${formatedInitialDate}&finalDate=${formatedFinalDate}&vada=${vada}`
    const { data } = await cmrApi.get(path)
    .catch(e =>{
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'No se han encontrado datos para estas fechas.',
            confirmButtonColor: '#414DBC',
            cancelButtonColor: '#6e7d88',
            
        })
        console.error ("Error técnico de la descarga: " + e)
     return
    
})
Swal.close()
    return data;
}



