import plannignApi from "@/api/PlanningApi"
import Swal from "sweetalert2"

export const getRange = (state,getters,rootState) => async () => {
    const vada = rootState.vada
    const path = `/api/v1/timeRange?vada=${vada}`
    const response = await plannignApi.get(path)
    if (response.status == '200') {
        return response.data.message
    } else {

        Swal.fire({
            icon: "error",
            title: `Error ${response.status}`,
            text: response.statusText,
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    }

}

export const getHidden = (state,getters,rootState) => async () => {
    const vada = rootState.vada
    const path = `/api/v1/timeHidden?vada=${vada}`
    const response = await plannignApi.get(path)
    if (response.status == '200') {
        return response.data.message
    } else {

        Swal.fire({
            icon: "error",
            title: `Error while getting time hidden`,
            text: response.statusText,
            confirmButtonColor: "#414DBC",
            cancelButtonColor: "#6e7d88"
        })
    }
}


