import { createStore } from "vuex";
import configModule from "@/modules/config/store/config";
import planningModule from "@/modules/planning/store/planning";
import collaboratorModule from "@/modules/collaborator/store/collaborator";
import dataModule from "@/modules/data/store/data";
import authModule from "@/modules/auth/store/auth";
import decaconvoModule from "@/modules/decaconvo/store/decaconvo";
import cmrstoreModule from "@/modules/cmrstore/store/cmrstore";
import createPersistedState from "vuex-persistedstate"
import SecureLS from 'secure-ls'

const ls = new SecureLS({ isCompression: false })
window.sessionStorage.getItem = (key) => ls.get(key) 
window.sessionStorage.setItem = (key, value) => ls.set(key, value)
window.sessionStorage.removeItem = (key) => ls.remove(key)

const store = createStore({
    state: {
        vada: localStorage.getItem('vada') == null ? '' : localStorage.getItem('vada'),
    },
    mutations: {
        setVada(state, value) {
            state.vada = value
        },

    },
    
    modules: {
        config: configModule,
        planning: planningModule,
        collaborator: collaboratorModule,
        data: dataModule,
        auth: authModule,
        decaconvo: decaconvoModule,
        cmrstore: cmrstoreModule

       
    },
    plugins: [createPersistedState({
        storage: window.sessionStorage
    })]
})


export default store