import Cookies from 'js-cookie';

export const isAuthenticated = (state) => {
    state.authenticated = true;
}

export const isAuthenticatedToFalse = (state) => {
    state.authenticated = false;
}

export const logout = (state) => {
    state.authenticated = false;
    Cookies.remove('attributesToken');
    Cookies.remove('token');
    Cookies.remove('authenticated');
    Cookies.remove('givenName');
    Cookies.remove('locale');
    Cookies.remove('dateLoginToday');
    Cookies.remove('attributesDecathlon');
}

export const setVada = (state, vada) => {
    state.vada = vada;
}
